module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"23Twenty Media","short_name":"23Twenty Media","description":"Photography | Videography in Philadelphia, Pennsylvania and beyond","homepage_url":"https://www.23twenty.com","start_url":"/","background_color":"#fff","theme_color":"#673ab7","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134867932-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"264828567509350"},
    }]
