// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photo-js": () => import("./../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-video-js": () => import("./../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-weddings-js": () => import("./../src/pages/weddings.js" /* webpackChunkName: "component---src-pages-weddings-js" */)
}

